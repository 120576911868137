<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row>
      <v-col>
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          color="primary"
        />
      </v-col>
    </v-row>
    <sequential-entrance from-top>
      <v-row>
        <v-col
          sm="12"
          md="12"
          lg="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-handshake"
            inline
          >
            <template v-slot:after-heading>
              <div class="text-h3 font-weight-light">
                Admin Partner Campaign for: {{ getpartnername }}
              </div>
            </template>
            <v-data-table
              :headers="headers"
              :items="getcampaigns"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Campaigns</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="warning"
                        dark
                        class="mb-2 ma-2"
                        to="/adminpartners"
                      >
                        Return
                      </v-btn>
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2 ma-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        New Campaign
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                          >
                            <v-text-field
                              v-model="editedItem.idnum"
                              outlined
                              :rules="[v => !!v || 'ID Num is required']"
                              label="ID Num"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="editedItem.label"
                              outlined
                              :rules="[v => !!v || 'Label is required']"
                              label="Label"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="editedItem.comission"
                              outlined
                              :rules="[v => !!v || 'Comission is required']"
                              label="Comission Percent"
                              required
                            ></v-text-field>
                            <v-dialog
                              v-model="modalStartDate"
                              max-width="300px"
                              persistent
                            >
                              <template v-slot:activator="{ on: { click } }">
                                <v-text-field
                                  v-model="editedItem.startDate"
                                  label="Start Date"
                                  required
                                  @click="click"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.startDate"
                                scrollable
                                actions
                              >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    @click="modalStartDate=false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    @click="modalStartDate = false"
                                  >
                                    OK
                                  </v-btn>
                                </v-card-actions>
                              </v-date-picker>
                            </v-dialog>
                            <v-dialog
                              v-model="modalEndDate"
                              max-width="300px"
                              persistent
                            >
                              <template v-slot:activator="{ on: { click } }">
                                <v-text-field
                                  v-model="editedItem.endDate"
                                  label="End Date"
                                  required
                                  @click="click"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editedItem.endDate"
                                scrollable
                                actions
                              >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="primary"
                                    @click="modalEndDate=false"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    @click="modalEndDate = false"
                                  >
                                    OK
                                  </v-btn>
                                </v-card-actions>
                              </v-date-picker>
                            </v-dialog>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialogDelete"
                    max-width="600px"
                  >
                    <v-card>
                      <v-card-title class="text-h5">
                        Are you sure you want to delete this item?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="closeDelete"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                        >
                          OK
                        </v-btn>
                        <v-spacer />
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div
                  class="d-flex justify-center"
                >
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-expand-x-transition>
                    <v-tooltip
                      :color="tooltipColor"
                      right
                    >
                      <template
                        v-slot:activator="{ on, attrs }"
                      >
                        <v-icon
                          color="purple"
                          v-bind="attrs"
                          v-on="on"
                          @click="copyItemURL(item)"
                          @mouseover="onTooltipMouseOver"
                        >
                          mdi-link
                        </v-icon>
                      </template>
                      <span>{{ tooltipText }}</span>
                    </v-tooltip>
                  </v-expand-x-transition>
                </div>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
    </sequential-entrance>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import { getCopyURL } from '../../../utils/common'

  export default {

    data: () => ({
      dialog: false,
      dialogDelete: false,
      startDateDialog: false,
      endDateDialog: false,
      modal: false,
      modalEndDate: false,
      modalStartDate: false,
      search: '',
      headers: [
        { text: 'Created', value: 'created', align: 'center', sortable: false },
        { text: 'ID', value: 'idnum', align: 'left', sortable: false },
        { text: 'Label', value: 'label', align: 'center', sortable: false },
        { text: 'Start Date', value: 'startDate', align: 'center', sortable: false },
        { text: 'End Date', value: 'endDate', align: 'center', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false },
      ],
      valid: false,
      campaigns: [],
      editedIndex: -1,
      editedItem: {
        idnum: '',
        type: '',
        label: '',
        startDate: '',
        endDate: '',
        comission: '',
        key: '',
      },
      defaultItem: {
        idnum: '',
        type: '',
        label: '',
        startDate: '',
        endDate: '',
        comission: '',
        key: '',
      },
      tooltipText: 'Copy partner URL',
      tooltipColor: '#000000',
    }),
    computed: {
      validForm () {
        let validform = false
        return validform
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Campaign' : 'Edit Campaign'
      },
      loading () {
        // return true
        return this.$store.getters.getloading
      },
      getcampaigns () {
        this.campaigns = []
        if (this.$store.getters.getLoadedPartnerCampaigns) {
          this.campaigns = this.$store.getters.getLoadedPartnerCampaigns
          this.campaigns.forEach(entry => {
            Object.assign(entry, { created: moment(entry.timestamp).format('YYYY-MM-DD HH:mm') })
          })
        }
        return this.campaigns
      },
      getpartnername () {
        let detail = ''
        if (this.$store.getters.getLoadedBlanketPartners) {
          detail = this.$store.getters.getLoadedBlanketPartners.find(entry => entry.key === this.partnerkey)
        }

        return detail.name
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.partnerkey = ''
      this.partnerkey = this.$route.params.partnerkey
      this.$store.dispatch('loadblanketpartners')
      this.$store.dispatch('loadPartnerCampaign', this.partnerkey)
    },
    methods: {
      initialize () {
        this.partners = this.$store.getters.getLoadedBlanketPartners
      },

      editItem (item) {
        this.editedIndex = this.campaigns.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.campaigns.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.campaigns.splice(this.editedIndex, 1)
        this.$store.dispatch('removerelease', this.editedItem)

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          console.log('update this item ', this.editedItem)
          Object.assign(this.partners[this.editedIndex], this.editedItem)
          // this.$store.dispatch('updateBlanketPartner', this.editedItem)
        } else {
          console.log('this must be new ', this.editedItem)
          this.campaigns.push(this.editedItem)
          let campRec = {
            key: this.partnerkey,
            idnum: this.editedItem.idnum,
            label: this.editedItem.label,
            comission: this.editedItem.comission,
            startDate: this.editedItem.startDate,
            endDate: this.editedItem.endDate
          }
          this.$store.dispatch('createPartnerCampaign', campRec)
        }
        this.$store.dispatch('loadblanketpartners')
        this.close()
      },

      onTooltipMouseOver () {
        // Reset to defaults
        this.tooltipText = 'Copy partner URL'
        this.tooltipColor = '#000000'
      },

      getPartnerField (field) {
        let detail = ''
        if (this.$store.getters.getLoadedBlanketPartners) {
          detail = this.$store.getters.getLoadedBlanketPartners.find(entry => entry.key === this.partnerkey)
        }

        return detail[field]
      },

      async copyItemURL (item) {
        try {
          const partner = this.$store.getters.getLoadedBlanketPartners.find(entry => entry.key === this.partnerkey)
          let { partnerurl = '' } = partner
          console.log({ partnerurl, partner })
          console.log(item)
          if (partnerurl) {
            await navigator.clipboard.writeText(`${getCopyURL(partner)}/${item.idnum}`)
            this.tooltipText = 'Copied!'
            this.tooltipColor = 'success'
          }
        } catch (e) {
          console.log('Cannot copy', e)
          this.tooltipText = 'Error! Cannot copy!'
          this.tooltipColor = 'error'
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.policy-numbers {
  font-size: 36px;
}
</style>
